import { defineComponentClasses } from '@visiontree/vue-ui'

interface Structure {
  container: never
}

type Options = object

export const getPageClasses = defineComponentClasses<Options, Structure>({
  classes: {
    container: 'h-full',
  },
})
