<template>
  <div :class="classes.container">
    <slot />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { getPageClasses } from './utils/getPageClasses'

const classes = computed(() => {
  return getPageClasses()
})
</script>
